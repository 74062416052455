<template>
  <userTemplate>
    <div class="bg-danger font-34 text-center pd-x-5">
      <el-row>
        <el-col
          :xs="{ span: 24, offset: 0 }"
          :sm="{ span: 16, offset: 4 }"
          :lg="{ span: 8, offset: 8 }"
        >
          <div>
            <p class="mg-y-6 text-white">{{ user.name }}</p>
          </div>
        </el-col>
      </el-row>
    </div>
    <el-row class="pd-x-5 bg-primary text-white text-center">
      <el-col
        :xs="{ span: 24, offset: 0 }"
        :sm="{ span: 16, offset: 4 }"
        :lg="{ span: 8, offset: 8 }"
        class="h-100 min-height-screen-255 pd-t-1 position-relative"
      >
        <div class="mg-t-1">
          <i class="fas fa-check-circle" style="font-size: 72px"></i>
          <h3>คุณเข้าร่วมห้อง {{ room.name }}</h3>
          <h2 class="font-28">เรียบร้อยแล้ว!</h2>
        </div>
        <div class="position-absolute w-100 text-center" style="bottom: 10px">
          <p>คุณจะเห็นชื่อคุณบนหน้าจอ</p>
        </div>
      </el-col>
    </el-row>
  </userTemplate>
</template>
<script>
import { HTTP } from "@/service/axios.js";
import userTemplate from "@/template/VoteUserTemplate";
export default {
  components: {
    userTemplate
  },
  mounted() {
    this.getStateUser();
    this.getRoom();
    this.intervalation = setInterval(() => {
      this.getRoom();
      this.getState();
    }, 1000);
  },
  data() {
    return {
      name: ""
    };
  },
  beforeDestroy() {
    clearInterval(this.intervalation);
  },
  watch: {
    step() {
      let next = this.allExam.filter(a => a.no == Number(this.step.qId) + 1);
      // console.log(next);
      if (this.step.qId < 16) {
        if (next[0].step.length > 0) {
          this.$router.push("/description");
        } else {
          this.$router.push("/testing");
        }
      }
    }
  },
  methods: {
    getStateUser() {
      let obj = this.room.roomId;
      HTTP.get(`state/${obj}`).then(res => {
        // console.log("state", res);
        if (res.data.success) {
          this.$store.commit("SET_STEP", res.data.obj);
          this.$router.push("/description");
        }
      });
    }
  }
};
</script>